/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LinkedInButton from '../../core/globals/buttons/LinkedInButton';
import FacebookButton from '../../core/globals/buttons/FacebookButton';

/**
 * @description - Social Media SignIn.
 * @returns {Node} - Social Buttons node.
 */
const SocialMediaSignIn = ({
  sitecoreContext,
  facebookText,
  linkedInText,
  redirectTo,
  getValues,
  onSuccess,
  onError,
  trigger,
  param,
  clearErrors,
}) => {
  const isSweden = sitecoreContext?.Country?.name.toLowerCase() === 'sweden';

  return (
    <Fragment>
      {!isSweden && (
        <FacebookButton
          text={facebookText}
          onLogged={onSuccess}
          onError={onError}
          trigger={trigger}
          appId={sitecoreContext?.ROMA?.fbAppId}
          clearErrors={clearErrors}
        />
      )}
      <LinkedInButton
        text={linkedInText}
        onLogged={onSuccess}
        onError={onError}
        redirectTo={redirectTo}
        appId={sitecoreContext?.ROMA?.lnAppId}
        trigger={trigger}
        getValues={getValues}
        param={param}
        clearErrors={clearErrors}
      />
    </Fragment>
  );
};

SocialMediaSignIn.defaultProps = {
  sitecoreContext: {},
};

SocialMediaSignIn.propTypes = {
  facebookText: PropTypes.string.isRequired,
  linkedInText: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(SocialMediaSignIn);
