import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './sidebarcards.scss';

/**
 * @description to show recruter card in job details
 * @returns {node} html
 */
const SidebarCardRecruiter = ({ t, fields, sitecoreContext }) => {
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase();
  const country_code = sitecoreContext?.Country?.code;
  return (
    <div className="recruiter-sidebar">
      <h2 className={`sidebar-title ${brandName}`}>{t('recruiter')}</h2>
      {Array.isArray(fields?.items) &&
        fields.items.map((recruiter, index) => {
          return (
            <div
              className={`sidebar-body ${country_code === 'NL' ? '' : 'final'}`}
              key={index}
              style={index === 0 ? { paddingTop: 0 } : {}}
            >
              {recruiter?.recImage && (
                <div className="recruiter-image">
                  <img src={recruiter?.recImage} alt="recruiter" />
                </div>
              )}
              {recruiter?.recruiterName && (
                <div className="recruiter-name">{recruiter.recruiterName}</div>
              )}

              {recruiter?.recruiterPhoneNumber?.trim(' ""') && (
                <a
                  href={`tel: ${recruiter?.recruiterPhoneNumber}`}
                  className={`social-item phone`}
                  aria-label="Phone number opens in a new window/tab"
                >
                  <div className="icon">
                    <div className="icon-phone"></div>
                  </div>
                  <div className="text text-phone">
                    {recruiter?.recruiterPhoneNumber}
                  </div>
                </a>
              )}

              {recruiter?.recEmailId && (
                <a
                  href={`mailto: ${recruiter?.recEmailId}`}
                  className={`social-item`}
                  aria-label="Email opens in a new window/tab"
                >
                  <div className="icon">
                    <div className="icon-mail"></div>
                  </div>
                  <div className="text">{recruiter?.recEmailId}</div>
                </a>
              )}

              {recruiter?.linkedinId &&
              country_code !== 'DE' && ( // fix for 24712 - Manpower Germany no linkedin Id needed.
                  <a
                    href={recruiter?.linkedinId}
                    className={`social-item`}
                    aria-label="LinkedIn opens in a new window/tab"
                  >
                    <div className="icon">
                      <div className="icon-ln"></div>
                    </div>
                    <div className="text">{recruiter?.linkedinId}</div>
                  </a>
                )}

              {(recruiter?.recBranchAddress || recruiter?.recBranchLocation) && (
                <>
                  <div className="sidebar-title" style={{ border: 'none' }}>
                    {t('branch-address')}
                  </div>
                  {recruiter?.recBranchAddress ? (
                    <div className="text">{recruiter?.recBranchAddress}</div>
                  ) : null}
                  {recruiter?.recBranchLocation ? (
                    <div className="text">{recruiter?.recBranchLocation}</div>
                  ) : null}
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

SidebarCardRecruiter.defaultProps = {
  fields: {
    items: [],
  },
  t: () => {},
  sitecoreContext: {},
};

SidebarCardRecruiter.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(SidebarCardRecruiter);
