import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Text,
  withSitecoreContext,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../globals/link/ButtonLink';
import { checkDataExists, getImg } from '../../../utils/helperUtils';
import './calloutbar.scss';
import NavLink from '../../core/globals/NavLinks';

/**
 *@description -  Calloutbar standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CalloutBar = (props) => {
  const { fields, sitecoreContext } = props;
  const {
    items = [],
    mode,
    title,
    text,
    ctaText,
    ctaUrl,
    backgroundImage,
    icon,
    componentBackgroundColor,
    navTitle,
  } = fields;

  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const checkCondition = checkDataExists(mode);
  const direction =
    checkCondition?.toLowerCase() === 'simple'
      ? '181deg'
      : 'to bottom' || 'to bottom';

  let color_code = [];
  const updatebackgroundColor =
    componentBackgroundColor &&
    componentBackgroundColor?.map((item) => {
      const { colorCode } = item?.fields;
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      color_code = color_code.split(',');
      if (color_code.length > 1) {
        color_code = `linear-gradient(${direction}, ${color_code[0]}, ${color_code[1]})`;
      } else {
        color_code = ` ${color_code}`;
      }
      return color_code;
    });
  const styleObj = {
    backgroundImage: backgroundImage
      ? `url(${mediaApi.updateImageUrl(
          getImg(backgroundImage)
        )}), url(${mediaApi.updateImageUrl(getImg(icon))})`
      : null,
  };
  return (
    <div
      className={`calloutbar-section ${
        checkCondition ? checkCondition : ''
      } ${brandName}`}
      style={{ background: `${updatebackgroundColor}` }}
    >
      {checkDataExists(mode) === 'withlogo' && (
        <>
          <div className="calloutbar-x" style={{ ...styleObj }}></div>
          <div
            className="calloutbar-x mobile"
            style={{
              backgroundImage: backgroundImage
                ? `url(${mediaApi.updateImageUrl(getImg(icon))})`
                : null,
            }}
          ></div>
        </>
      )}
      <div className="calloutbar-body container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2
              className={`title ${checkCondition === 'overview' ? '' : 'reversed'}`}
              id={navTitle?.value}
            >
              <Text field={title} />
            </h2>
            <p className="reversed large">
              <Text field={text} />
            </p>

            {checkCondition === 'overview' ? (
              <ButtonLink ctaText={ctaText} ctaUrl={ctaUrl} />
            ) : items?.length > 0 ? (
              <div>
                {items?.map((item, index) => {
                  return (
                    <span>
                      <NavLink
                        url={item?.fields?.url?.value}
                        ariaLabel={
                          item?.fields?.url?.value?.target
                            ? `${
                                item?.fields?.svgImage?.value?.alt || `-${index + 1}`
                              } opens in a new tab`
                            : ''
                        }
                      >
                        <Image
                          field={item?.fields?.svgImage}
                          className={`icon-badges--${index + 1}`}
                        />
                      </NavLink>
                    </span>
                  );
                })}
              </div>
            ) : (
              <ButtonLink cssClass="white-bg" ctaText={ctaText} ctaUrl={ctaUrl} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CalloutBar.defaultProps = {
  fields: {
    title: {},
    text: {},
    mode: {},
    ctaText: '',
    ctaUrl: '',
    backgroundImage: '',
    icon: '',
    componentBackgroundColor: [],
  },
  sitecoreContext: {},
};

CalloutBar.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    },
    mode: {
      value: PropTypes.oneOf(['withlogo', 'simple', 'overview']),
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.shape({}),
    },

    backgroundImage: {
      value: PropTypes.string,
    },
    icon: {
      value: PropTypes.string,
    },
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(CalloutBar);
