/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../globals/buttons/Button';
import Textbox from '../globals/forms/Textbox';
import CardEvent from '../Cards/CardEvent';
import TypeAheadSimple from '../Forms/FormFields/TypeAheadSimple';
import { PARTNER_PROJECT } from '../../../constants';
import { useFetchData } from '../../../hooks/http-client';
import Loader from '../ErrorBoundary/Loader';
import Error from '../ErrorBoundary/Error';
import NoData from '../ErrorBoundary/NoData';
import { DATE_FORMATS } from '../../../utils/enums';
import './RelevantJobSearch.scss';

/**
 * @description - Partner Project Search
 * @returns {Node} - component view.
 */
const RelevantJobs = ({ fields, t }) => {
  const cardsShown = 9;
  const refProjects = useRef();
  const [formVal, setFormVal] = useState({
    searchKeyword: '',
    location: '',
    JobCategory: fields?.jobCategory?.value || '',
  });
  const [pageNum, setPageNum] = useState(1);
  const [cardsList, setCardsList] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { data, loading, error, post } = useFetchData();

  const jobs = data?.jobs ? data.jobs : [];
  const locations = data?.locations ? data?.locations : [];
  const jobCount = data?.jobCount ? data.jobCount : 0;
  const keyword = data?.keyword ? data.keyword : '';
  const location = data?.location ? data.location : '';

  /**
   * @description - Gets the default Projects.
   * @returns {*} - Array of objects for projects.
   */
  useEffect(() => {
    post(`${PARTNER_PROJECT.POST_PARTNER_PROJECT_RELEVANT_JOBS.URL}`, {
      ...formVal,
    });
  }, []);

  /**
   * @description - Gets the show more Projects.
   * @returns {*} - Array of objects for projects.
   */
  useEffect(() => {
    viewShowMoreButton();
    arrangeCardsList();
  }, [jobCount, pageNum]);

  /**
   * @description - onChange Location Handler.
   * @param {String} loc - location selected.
   * @returns {null} - return nothing.
   */
  const onChangeLocationHandler = (loc) => {
    setFormVal({ ...formVal, location: loc.toLowerCase() });
  };

  /**
   * @description - onChange keyword Handler.
   * @param {Object} ev - change event.
   * @returns {null} - return nothing.
   */
  const onChangeKeywordHandler = (ev) => {
    const { value } = ev.target;
    setFormVal({ ...formVal, SearchKeyword: value });
  };

  /**
   * @description - onSubmit form.
   * @param {Object} ev - submit event.
   * @returns {null} - return nothing.
   */
  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    setPageNum(1);
    setCardsList([]);
    post(`${PARTNER_PROJECT.POST_PARTNER_PROJECT_RELEVANT_JOBS.URL}`, {
      ...formVal,
    });
  };

  /**
   * @description - arrange Cards List.
   * @returns {null} - return nothing.
   */
  const arrangeCardsList = () => {
    const cards = jobs?.filter((job, index) => index < cardsShown * pageNum);
    const dateList =
      cards.length > 0 &&
      cards?.map((data) => {
        let customizedDate;
        if (data?.created) {
          const removeHrsfromdate = data?.created?.split(' ')[0];
          const changedDateOrder = new Date(
            removeHrsfromdate?.split('/').reverse().join('/')
          );
          const isInValidDate = new Date(removeHrsfromdate);
          const isValidDateFormat =
            isInValidDate.toString() === 'Invalid Date'
              ? changedDateOrder
              : isInValidDate;
          customizedDate = JSON.stringify(new Date(isValidDateFormat.toString()));
          const dateValue = customizedDate.slice(1, 11);
          return { ...data, created: dateValue };
        }
        return data;
      });
    const cardDate =
      dateList.length > 0 &&
      dateList?.sort(function (a, b) {
        return b?.created < a?.created ? -1 : 1;
      });
    setCardsList(cardDate);
  };

  /**
   * @description - Show/Hide Show more button.
   * @returns {null} - return nothing.
   */
  const viewShowMoreButton = () => {
    setCardsList([]);
    if (jobs?.length > cardsShown * pageNum) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  };

  /**
   * @description - Returns text for ribbon.
   * @returns {string} - return text for ribbon.
   */
  const getRibbonString = () => {
    if (keyword === '' && location === '') {
      return `${jobCount} ${t('partner-project-count')}`;
    } else if (keyword === '') {
      return `${jobCount} ${t('partner-project-count')} in ${location}`;
    } else if (location === '') {
      return `${jobCount} ${t('partner-project-count')} for '${keyword}'`;
    }
    return `${jobCount} ${t(
      'partner-project-count'
    )} for '${keyword}' in ${location}`;
  };

  return (
    <>
      <section className="search-header-partner">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 offset-lg-1 col-xl-10 offset-xl-1">
              <div className="search-options">
                <div className="active">{t('partner-search-title')}</div>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div className={'search-projects active'}>
                  <div className="row">
                    <div className="col-lg-6">
                      <Textbox
                        id="partner-projects-keyword"
                        placeholder={t('partner-project-keywords')}
                        onHandleChange={onChangeKeywordHandler}
                      />
                    </div>
                    <div className="col-lg-3">
                      <TypeAheadSimple
                        placeholder={t('partner-location')}
                        icon={fields?.filterImage?.value?.src || ''}
                        data={locations || []}
                        onChange={onChangeLocationHandler}
                      />
                    </div>
                    <div className="col-lg-3">
                      <Button
                        type="submit"
                        cssClass="white-bg"
                        text={t('partner-search-button')}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="search-ribbon-partner">
        <div className="container">
          <div className="row">
            <div className="search-ribbon-partner-wrap">
              <div className="search-ribbon-partner-inner">
                <div className="results">
                  <div
                    className="text"
                    ref={refProjects}
                    role="tablist"
                    tabIndex={-1}
                    aria-disabled={true}
                    onFocus={() => null}
                  >
                    {getRibbonString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="search-partner-result">
              {loading && <Loader />}
              {!loading && error && <Error errorMsg={t('error-message')} />}
              {!loading && !error && jobs?.length === 0 && (
                <NoData message={getRibbonString()} />
              )}
              {!loading &&
                !error &&
                jobs?.length != 0 &&
                cardsList?.length > 0 &&
                cardsList?.map((card) => {
                  const propsCard = {
                    headline: card?.title,
                    location: card?.location,
                    locationPin: fields?.filterImage?.value?.src || '',
                    startDate:
                      card?.deadline ||
                      moment(card?.created)?.format(DATE_FORMATS.LOCALE),
                    ctaText: t('more-information'),
                    ctaUrl: {
                      href: card?.applyUrl,
                      linktype: 'internal',
                      target: '',
                    },
                    external: true,
                    isPartner: true,
                  };
                  return (
                    <div key={card?.applyUrl} className="cardWrap">
                      <CardEvent {...propsCard} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="search-partner-showmore">
              {showMore && (
                <Button
                  cssClass="blue"
                  text={t('load-more')}
                  handleButtonClick={() => {
                    setShowMore(false);
                    setPageNum(pageNum + 1);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

RelevantJobs.propTypes = {
  t: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({
    filterImage: PropTypes.shape({}).isRequired,
  }).isRequired,
};

RelevantJobs.defaultProps = {};

export default withTranslation()(RelevantJobs);
