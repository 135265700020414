import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import ButtonLink from '../../globals/link/ButtonLink';
import { getImg } from '../../../../utils/helperUtils';

import '../page-banners.scss';

/**
 * @description skewed page banner component
 * @param {*} fields - component content fields
 * @returns {node} - html
 */
const BannerSkewed = ({ fields, sitecoreContext }) => {
  const {
    title,
    subTitle,
    text,
    ctaText,
    ctaUrl,
    backgroundImage,
    colorMode,
    offset,
  } = fields;
  const imgVal = mediaApi.updateImageUrl(getImg(backgroundImage));
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  return (
    <section
      className={`banner-section skewed ${brandName} ${
        colorMode?.value ? colorMode.value.toLowerCase() : ''
      }`}
    >
      <figure
        className="banner-image"
        style={{
          backgroundImage: `url(${imgVal})`,
        }}
      >
        <img className="sr-only" alt={backgroundImage?.value?.alt} src={imgVal} />
      </figure>
      {/* <Image field={backgroundImage} className="banner-image" /> */}
      <div className="skewed-rectangle"></div>
      <div className="container">
        <div className="row">
          <div className={'col-lg-5 col-12 ' + (offset?.value ? 'offset-lg-6' : '')}>
            <div className="sub-title">{subTitle && <Text field={subTitle} />}</div>
            <h1 className="title">{title && <Text field={title} />}</h1>
          </div>
        </div>
        <div className="row">
          <div className={'col-lg-5 col-12 ' + (offset?.value ? 'offset-lg-6' : '')}>
            <p className="large reversed" id="section-description">
              {text && <Text field={text} />}
            </p>
            {brandName === 'manpower' ? (
              <ButtonLink
                cssClass="white-bg"
                ctaText={ctaText}
                ctaUrl={ctaUrl}
                arrow
              />
            ) : (
              <ButtonLink cssClass="reversed" ctaText={ctaText} ctaUrl={ctaUrl} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BannerSkewed.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
    colorMode: { value: 'BLUE' },
    offset: { value: false },
  },
  sitecoreContext: {},
};

BannerSkewed.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    colorMode: PropTypes.shape({ value: PropTypes.oneOf(['', 'BLUE']) }),
    offset: PropTypes.shape({ value: PropTypes.bool }),
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(BannerSkewed);
