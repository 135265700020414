/* eslint-disable require-jsdoc */
/* eslint-disable valid-jsdoc */
import React, { useEffect, useRef } from 'react';
import AuthWrapper from '../LoginMSAL/AuthWrapper';
import { isNullorEmpty } from '../../../utils/helperUtils';
/**
 *  This component is triggered when user opens multiple tabs and logout from one of the tab, then the user automatically logs out from all the tabs
 */
const LogoutListener = () => {
  const buttonRef = useRef(null);
  const handleStorageChange = (event) => {
    const userSession = JSON.parse(sessionStorage.getItem('usersession'));
    if (
      event.key === 'authState' &&
      event.newValue === 'UNAUTHENTICATED' &&
      !isNullorEmpty(userSession?.user)
    ) {
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = (logout) => {
    logout();
  };

  return (
    <AuthWrapper>
      {({ logout }) => {
        return (
          <button
            className="d-none"
            ref={buttonRef}
            onClick={() => {
              handleLogout(logout);
            }}
          >
            logout
          </button>
        );
      }}
    </AuthWrapper>
  );
};
export default LogoutListener;
