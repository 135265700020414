/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AuthWrapper from './AuthWrapper';
import { authstates } from '../../../utils/enums';
import useRoveFocus from '../../../hooks/useRoveFocus';
import { DEVICE, SOCIAL_LOGIN_VALUES } from '../../../constants';
import { getWidth, isNullorEmpty } from '../../../utils/helperUtils';
import store from '../../../redux/store';

/**
 * @description - Push authentication type to storage.
 * @returns {undefined}
 */
const pushToStorage = () => {
  localStorage.setItem('authenticationType', 'Create an account/Sign in');
};

/**
 * @description- user links after login
 * @param {Object} - component props
 * @returns {HTML} - html
 */
const Links = ({ to, handleClick, character, index, setFocus, ctaText }) => {
  const ref = useRef(null);

  const handleSelect = useCallback(() => {
    setFocus(index);
  }, [character, index, setFocus]);

  return (
    <li key={index}>
      <Link
        to={to}
        onClick={() => handleClick()}
        tabIndex={0}
        onKeyUp={handleSelect}
        ref={ref}
      >
        <Text field={ctaText} />
      </Link>
    </li>
  );
};

Links.propTypes = {
  to: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  character: PropTypes.shape({}),
  focus: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setFocus: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
};

Links.defaultProps = {
  to: '',
  handleClick: () => {},
  character: {},
  focus: false,
  index: 0,
  setFocus: () => {},
  ctaText: '',
};

/**
 * @description _ MSALView Component.
 * @param {object} props - Props passed.
 * @returns {Node} - HTML node.
 */
const MSALView = ({ t, fields, sitecoreContext }) => {
  const [focused, setFocused] = useState(false);
  const [source, setSource] = useState('');
  const { title } = fields;
  const [focus, setFocus] = useRoveFocus(fields.items.length);
  const welcomeRef = useRef(null);
  const loginRef = useRef(null);
  const loginReducer = useSelector((state) => state.loginReducer);

  const isLocalStorageAvailable =
    typeof window !== 'undefined' && typeof localStorage !== 'undefined';
  const authState = isLocalStorageAvailable ? localStorage.getItem('authState') : '';
  const {
    adb2cConfigReducer: { msalConfig },
  } = store.getState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const sourceParam = urlParams.get('source');
    setSource(sourceParam);
  }, []);

  useEffect(() => {
    // If user session is available in local storage then remove
    if (isLocalStorageAvailable) {
      const userSession = localStorage.getItem('usersession');
      if (userSession) {
        localStorage.removeItem('usersession');
      }
    }
  }, []);

  useEffect(() => {
    const userSession = JSON.parse(sessionStorage.getItem('usersession'));
    if (
      authState === authstates.AUTHENTICATED &&
      isNullorEmpty(userSession?.user) &&
      isLocalStorageAvailable &&
      msalConfig
    ) {
      loginRef.current.click();
      toggleClickDisable(true);
    }
    return () => {
      toggleClickDisable(false);
    };
  }, [authState, msalConfig]);

  const handleLogin = (login) => {
    pushToStorage();
    login();
  };

  const toggleClickDisable = (disable) => {
    document.addEventListener(
      'click',
      function (event) {
        if (disable) {
          event.stopPropagation();
          event.preventDefault();
        }
      },
      true
    );
  };

  return (
    <AuthWrapper>
      {({ authenticationState, user, login, logout, showLogin }) => {
        if (!showLogin) return;
        switch (authenticationState) {
          case authstates.AUTHENTICATED:
            const firstName = user ? user?.firstName : '';
            /**
             * @description - Logging Out user.
             * @returns {undefined}
             */
            const logoutUser = () => {
              if (window?.dataLayer) {
                const idpVal = loginReducer?.idToken?.idTokenClaims?.idp;
                window.dataLayer.push({
                  event: 'Authentication',
                  eventCategory: 'Sign Out',
                  eventAction:
                    SOCIAL_LOGIN_VALUES.get(idpVal?.toLowerCase()) || idpVal,
                  eventLabel: 'Successful Sign Out',
                });
              }
              logout();
            };

            /**
             * @description Below condition is written to call logout method based on the query params. This is implemented for mobile app.
             * @returns {undefined}
             */
            if (source === 'mobileLogout') {
              logoutUser();
            }

            /**
             * @description toggle menuitem list
             * @param {*}  -handle click
             * @returns {undefined}
             */
            const handleClick = () => {
              setFocused(false);
            };

            /**
             * @description - Open/Close menu on enter.
             * @param {object} e - Event object.
             * @returns {undefined}
             */
            const onEnter = (e) => {
              if (e && e.keyCode === 13) {
                logoutUser();
              }
            };

            /**
             *
             * @param {*} e
             */
            const handleKey = (e) => {
              if (e && (e.keyCode === 13 || e.keyCode === 32)) {
                e.preventDefault();
                setFocused(!focused);
              }
            };

            return (
              <div
                className={`main-nav-item login logged-in ${
                  sitecoreContext?.Country?.brandName.toLowerCase() === 'manpower'
                    ? 'login--mobile'
                    : ''
                } ${
                  //className only for manpower header
                  focused ? 'link-focused' : ''
                }`}
                onFocus={() => {
                  if (
                    document &&
                    !document.getElementsByClassName('hide_outline').length
                  )
                    setFocused(true);
                }}
                onMouseOver={() => {
                  if (getWidth() > DEVICE.MOBILE.WIDTH) setFocused(true);
                }}
                onMouseLeave={() => setFocused(false)}
              >
                <a
                  href="#"
                  aria-haspopup="true"
                  aria-expanded={focused}
                  aria-controls="dp-menu"
                  title="User Login"
                  id="menubutton"
                  onClick={() => setFocused(!focused)}
                  onKeyDown={handleKey}
                >
                  <span>
                    <div className="user-icon"></div>
                  </span>
                </a>
                <div
                  className={`main-nav-dropdown login-dropdown ${
                    focused ? 'link-active' : 'link-hidden'
                  }`}
                >
                  <div className="main-nav-dropdown-inside">
                    <div className="triangle login-triangle"></div>
                    <div className="main-nav-user-block">
                      <div className="welcome-user" tabIndex={-1} ref={welcomeRef}>
                        {t('welcome-title')} {firstName}
                      </div>
                      <div className="main-nav-user-links">
                        <ul className="login-submenu" id="dp-menu">
                          {fields && fields.items && fields.items.length
                            ? fields.items.map((item, index) => (
                                <Links
                                  key={index}
                                  to={item?.fields?.ctaUrl?.value?.href}
                                  handleClick={handleClick}
                                  setFocus={setFocus}
                                  index={index}
                                  focus={focus === index}
                                  character={item}
                                  ctaText={item?.fields?.ctaText}
                                />
                              ))
                            : null}
                          <li>
                            <a
                              href="#"
                              onClick={logoutUser}
                              onKeyDown={onEnter}
                              onBlur={() => setFocused(false)}
                              tabIndex="0"
                            >
                              {t('sign-out')}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          case authstates.PROGRESS:
            return (
              <div
                className={`main-nav-item login ${
                  sitecoreContext?.Country?.brandName === 'Manpower'
                    ? 'login--mobile'
                    : ''
                }`}
              >
                <a href="#" aria-label="Progress">
                  <span>
                    <div className="user-icon"></div>

                    <div className="login__text">
                      <Text field={title} />
                    </div>
                  </span>
                </a>
              </div>
            );
          default:
            return (
              <>
                <div
                  className={`main-nav-item login ${
                    sitecoreContext?.Country?.brandName === 'Manpower'
                      ? 'login--mobile'
                      : ''
                  }`}
                >
                  {' '}
                  {/*className only for manpower header */}
                  <a
                    href="#"
                    onClick={() => {
                      pushToStorage();
                      login();
                    }}
                    onKeyDown={(e) => {
                      if (e && (e.keyCode === 13 || e.keyCode === 32)) {
                        pushToStorage();
                        login();
                      }
                    }}
                    tabIndex="0"
                    aria-label="User Login"
                    aria-expanded="false"
                  >
                    <span>
                      <div className="user-icon"></div>

                      <div className="login__text">
                        <Text field={title} />
                      </div>
                    </span>
                  </a>
                </div>
                <button
                  className="d-none"
                  ref={loginRef}
                  onClick={() => handleLogin(login)}
                >
                  Login
                </button>
              </>
            );
        }
      }}
    </AuthWrapper>
  );
};

MSALView.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
  }),
  sitecoreContext: PropTypes.shape({}),
  isMobile: PropTypes.bool.isRequired,
};

MSALView.defaultProps = {
  fields: {
    items: [],
    title: '',
  },
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(MSALView));
