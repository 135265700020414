import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  mediaApi,
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../../globals/link/ButtonLink';
import { getImg } from '../../../../utils/helperUtils';
import { bannerLoaded } from '../../../../redux/actions/actions';
import { useDispatch } from 'react-redux';

import '../page-banners.scss';

/**
 * @description skewed page banner component
 * @param {*} fields - component content fields
 * @returns {node} - html
 */
const TallBanner = (props) => {
  const { fields = {}, BreadCrumb } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    backgroundimage,
    colorMode,
    offset,
  } = fields;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bannerLoaded('TallBanner', true));
  });

  return (
    <section
      className={`banner-section location tall ${
        colorMode?.value ? colorMode.value.toLowerCase() : ''
      }`}
    >
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(
            getImg(backgroundimage)
          )})`,
        }}
      ></div>
      <div className="skewed-rectangle"></div>
      <div className="container">
        <div className="row">
          <div
            className={
              'col-xl-4 col-lg-6 col-12 ' + (offset?.value ? 'offset-lg-6' : '')
            }
          >
            <div className="header-container">
              {BreadCrumb?.map((component, index) => {
                if (component.props && component.props.type === 'text/sitecore')
                  return component;
                return (
                  <Fragment key={index}>
                    <div className={`themeColor`}>{component}</div>
                  </Fragment>
                );
              })}
            </div>
            <h1 className="title">{title && <Text field={title} />}</h1>
          </div>
        </div>
        <div className="row">
          <div
            className={
              'col-xl-4 col-lg-6 col-12 ' + (offset?.value ? 'offset-lg-6' : '')
            }
          >
            <p className="large reversed" id="section-description">
              {text && <Text field={text} />}
            </p>

            <ButtonLink cssClass="white-bg" ctaText={ctaText} ctaUrl={ctaUrl} />
          </div>
        </div>
      </div>
    </section>
  );
};

TallBanner.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
    colorMode: { value: 'BLUE' },
    offset: { value: false },
  },
  BreadCrumb: [],
};

TallBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    colorMode: PropTypes.shape({ value: PropTypes.oneOf(['', 'BLUE']) }),
    offset: PropTypes.shape({ value: PropTypes.bool }),
  }),
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
};

const headerContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' }])(
    TallBanner
  )
);
export default headerContainer;
