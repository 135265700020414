/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import { useLocation } from 'react-router-dom';
import { withRouter, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardArticle from '../Cards/CardArticle';
import CardAd from '../Cards/CardAd';
import ArticleFilters from '../Filters/ArticleFilters';
import './blogcardInsights.scss';
import { BLOG_INSIGHT_CARDBLOCK } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import Loader from '../ErrorBoundary/Loader';
import Error from '../ErrorBoundary/Error';
import SnackBar from '../ErrorBoundary/SnackBar';
import Pagination from '../globals/pagination/Pagination';
import { Helmet } from 'react-helmet';
import { DATE_FORMATS } from '../../../utils/enums';
import { useSnackbar } from '../../../hooks/useSnackbar';

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
const getId = (id = '') => {
  return id ? id.replace(/\-/g, '') : null;
};
/**
 * @description - BlogArticleDetailBlock.
 * @param {object} param0 - Input params.
 * @returns {Node} - HTML components.
 */
const BlogArticleDetailBlock = ({ rendering, fields, t, sitecoreContext }) => {
  const cardBlocks = rendering?.placeholders['jss-content'] || [];
  const filterBlocks = rendering?.placeholders['jss-right'] || [];
  const { jobCardPosition, count = {}, largeCards } = rendering.fields;
  const { searchCanonicalUrl, searchUrl } = fields;
  const routeFields = sitecoreContext?.route?.fields;
  const { noFollow, noIndex } = routeFields;
  const contentFollow = noFollow?.value === false ? 'follow' : 'noFollow';
  const contentIndex = noIndex?.value === false ? 'index' : 'noIndex';
  const large_Cards = largeCards.value;
  const urlParams = new URLSearchParams(window?.location?.search);
  const history = useHistory();
  const cardRef = useRef(null);

  const { isActive, message, openSnackBar } = useSnackbar();

  let cardAdArticles = [];
  let newArray = [];
  let newArray1 = [];
  const [articleItems, setArticleItems] = useState([]);
  const [articleFilterItem, setArticleFilters] = useState([]);
  const [imageCardItems, setImageCardItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromheader, setFromheader] = useState(false);
  const [initialstate, setInitialstate] = useState('default');
  const [searchKey, setsearchkey] = useState('');
  const [selectedVals, setSelectedVals] = useState([]);
  const [dataItem, setDataItem] = useState([]);
  const dateFormat = sitecoreContext?.Country?.dateFormat;
  const [headerData, setHeaderData] = useState([]);
  const [error, setError] = useState(false);
  const page = urlParams.get('page') ?? 1;
  const hasPageParam = urlParams.has('page');
  const [currentPage, setCurrentPage] = useState(1);
  const totalCount = articleItems?.length;
  let newHeaderData = [];
  let searchKeyword = '';
  let audience_header = [];
  let contentTypes_header = [];
  let topics_header = [];
  const location = useLocation();
  const [contentType_fromscroll, setContentType] = useState(
    getId(location?.query?.contentType?.id)
  );
  const [topic_fromscroll, setTopic] = useState(getId(location?.query?.topic?.id));
  useEffect(() => {
    let audience = [];
    let contentTypes = [];
    let topics = [];
    const queryStrings = new URLSearchParams(window.location.search);

    if (
      queryStrings.get('audience') != null ||
      queryStrings.get('contentTypes') != null ||
      queryStrings.get('topics') != null ||
      contentType_fromscroll != null ||
      topic_fromscroll != null
    ) {
      if (queryStrings.get('audience') != null) {
        const audience_pageval = queryStrings.get('audience');

        const audienceval_fromlnk = audience_pageval.split(',value=');
        const audience_valfromquery = audienceval_fromlnk[0];
        const audience_titlefromquery = audienceval_fromlnk[1]?.replace('$', ' & ');

        audience.push({
          key: audience_valfromquery,
          value: audience_titlefromquery,
        });
      }
      if (queryStrings.get('contentTypes') != null) {
        const cnttypes_valfromlink = queryStrings
          .get('contentTypes')
          .split(',value=');
        const cnttypes_valfromquery = cnttypes_valfromlink[0];
        const cnttypes_titlefromquery = cnttypes_valfromlink[1]?.replace('$', ' & ');

        contentTypes.push({
          key: cnttypes_valfromquery,
          value: cnttypes_titlefromquery,
        });
      }
      if (contentType_fromscroll != null) {
        const cnttypes_valfromquery = contentType_fromscroll;
        contentTypes.push({
          key: cnttypes_valfromquery,
          value: '',
        });
      }

      if (queryStrings.get('topics') != null) {
        const topic_valfromlink = queryStrings.get('topics').split(',value=');
        const topic_val_header = topic_valfromlink[0];
        const topics_titlefromquery = topic_valfromlink[1]?.replace('$', ' & ');

        topics.push({ key: topic_val_header, value: topics_titlefromquery });
      }
      if (topic_fromscroll != null) {
        const topic_val_header = topic_fromscroll;
        const topics_titlefromquery = '';

        topics.push({ key: topic_val_header, value: topics_titlefromquery });
      }

      newHeaderData.push({
        audience,
        contentTypes,
        topics,
      });
      audience_header = audience.filter(
        (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
      );
      contentTypes_header = contentTypes.filter(
        (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
      );
      topics_header = topics.filter(
        (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
      );
      setDataItem(newHeaderData);
      setInitialstate('search');
      setFromheader(true);
    } else {
      setHeaderData([]);
      setDataItem([]);
      setInitialstate('default');
      setFromheader(false);
    }
  }, [location.search]);

  useEffect(() => {
    let postData = {};
    var filter = {
      audience: audience_header,
      contentTypes: contentTypes_header,
      topics: topics_header,
    };
    removeEmptyOrNull(filter);
    if (newHeaderData && newHeaderData.length > 0) {
      postData = {
        largeCards: large_Cards,
        filter,
      };
    } else {
      postData = {
        largeCards: large_Cards,
      };
    }
    setLoading(true);
    dataFetcher(BLOG_INSIGHT_CARDBLOCK.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setArticleItems(response?.data?.articleItems);
        setInitialstate('default');
      },
      (error) => {
        setLoading(false);
        <Error errorMsg={t('error-message') || error?.message} />;
      }
    );
    const filterPostData = {};
    dataFetcher(BLOG_INSIGHT_CARDBLOCK.POST_FILTER_URL, 'POST', filterPostData).then(
      (response) => {
        setLoading(false);
        setArticleFilters(response?.data);
        if (newHeaderData && newHeaderData.length > 0) {
          newHeaderData = newHeaderData.concat(response?.data);
          setHeaderData(newHeaderData);
        }
        setInitialstate('default');
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [location.search]);

  /**
   * updateFiltr values
   */
  const updateFilterVals = (e, item, index, values) => {
    let newVals = [];

    const data = [];
    const audience_val = [];
    const cnttype_val = [];
    const clienttopics_val = [];
    const jobseekertopics_val = [];
    const topics_val = [];
    values.map((items) => {
      items.map((element, index) => {
        if (element.key === item.key) {
          items.splice(index, 1);
        }
      });
    });
    values.map((items) => {
      items.map((element, index) => {
        if (element.name === 'audience') {
          audience_val.push({ key: element.key, value: element.title });
        }
        if (element.name === 'contentTypes') {
          cnttype_val.push({ key: element.key, value: element.title });
        }
        if (element.name === 'clientTopics') {
          topics_val.push({ key: element.key, value: element.title });
        }
        if (element.name === 'jobSeekerTopics' || element.name === 'topics') {
          topics_val.push({ key: element.key, value: element.title });
        }
      });
    });

    const audience = [];
    const contentTypes = [];
    const topics = [];
    let searchFilterData = '';
    audience_val.map((item, index) => {
      audience[index] = item;
    });
    cnttype_val.map((item, index) => {
      contentTypes[index] = item;
    });
    topics_val.map((item, index) => {
      topics[index] = item;
    });
    values?.length > 0 ? setInitialstate('search') : setInitialstate('default');
    setDataItem(values);
    let newvals = [];
    newvals.push({ audience, contentTypes, topics });
    setSelectedVals(newvals);
    if (fromheader === true) {
      newvals = newvals.concat(articleFilterItem, 'remove');
      setHeaderData(newvals);
      if (newvals[0].length === 0) {
        setFromheader(false);
      }
    }
    let SearchData = {};
    var filter = {
      audience: audience,
      contentTypes: contentTypes,
      topics: topics,
    };
    removeEmptyOrNull(filter);
    const filterval =
      Object.keys(filter).length === 0 && filter.constructor === Object;

    if (searchKey !== '') {
      setInitialstate('search');
      SearchData = {
        searchKeyword: searchKey,
        filter,
      };
    } else if (filterval === true && searchKey === '') {
      setInitialstate('default');
      SearchData = {
        largeCards: large_Cards,
      };
    } else {
      setInitialstate('search');
      SearchData = {
        filter,
      };
    }

    getArticleItems(SearchData);
  };
  const setSelectedValues = (values) => {
    const audience = [];
    const contentTypes = [];
    for (let i = 0; i < values?.length; i++) {
      if (values && values[i]?.name === 'audience') {
        audience.push({ value: values[i]?.title, key: values[i]?.key });
      }
      if (values && values[i].name === 'contentTypes') {
        contentTypes.push({ value: values[i]?.title, key: values[i]?.key });
      }
    }
    let newArray_vals = [];
    newArray_vals.push({ audience, contentTypes });
    setSelectedVals(newArray_vals);
  };
  const sendFilterItemtoblog = (values) => {
    const audience_val = [];
    const conntype_val = [];
    for (let i = 0; i < values.length; i++) {
      if (values && values[i].name === 'audience') {
        audience_val.push({ value: values[i].title, key: values[i].key });
      }
      if (values && values[i].name === 'contentTypes') {
        conntype_val.push({ value: values[i].title, key: values[i].key });
      }
    }
    const audience = [];
    const cntType = [];
    let searchFilterData = '';
    audience_val.map((item, index) => {
      audience[index] = item;
    });
    conntype_val.map((item, index) => {
      cntType[index] = item;
    });
    if (cntType.length > 0) {
      searchFilterData = {
        audience: audience,
        contentTypes: cntType,
      };
    } else {
      searchFilterData = {
        audience: audience,
      };
    }
    getArticleFilters(searchFilterData);
  };
  const cancelFilterVals = () => {
    const searchFilterData = {
      filter: {},
    };
    getArticleFilters(searchFilterData);
  };
  const handleFilteredValues = (values) => {
    values = values.filter(
      (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
    );
    if (values.length > 0) {
      handleFilteredResult(values);
    }
  };
  const handleFilteredResult = (values) => {
    const audience_val = [];
    const cnttype_val = [];
    const clienttopics_val = [];
    const jobseekertopics_val = [];
    for (let i = 0; i < values?.length; i++) {
      if (values && values[i]?.name === 'audience') {
        audience_val.push({
          key: values[i].key,
          value: values[i].title,
        });
      }
    }

    const audience = [];
    audience_val.map((item, index) => {
      audience[index] = item;
    });
    for (let i = 0; i < values?.length; i++) {
      if (values && values[i]?.name === 'contentTypes') {
        cnttype_val.push({
          key: values[i].key,
          value: values[i].title,
        });
      }
    }
    const contentTypes = [];
    const topics_val = [];
    cnttype_val.map((item, index) => {
      contentTypes[index] = item;
    });
    for (let i = 0; i < values?.length; i++) {
      if (values && values[i]?.name === 'clientTopics') {
        topics_val.push({
          key: values[i].key,
          value: values[i].title,
        });
      } else if (values && values[i]?.name === 'jobSeekerTopics') {
        topics_val.push({
          key: values[i].key,
          value: values[i].title,
        });
      }
    }

    let topics = [];
    topics_val.map((item, index) => {
      topics[index] = item;
    });
    newArray1.push({ audience, contentTypes, topics });

    newArray1 = newArray1.concat(selectedVals);
    setSelectedVals(newArray1);
    const audience_array = [];
    const cntType_array = [];
    const topic_array = [];
    for (let i = 0; i < newArray1.length; i++) {
      if (newArray1[i]?.audience?.length > 0) {
        audience_array.push(newArray1[i]?.audience[0]);
      }
      if (newArray1[i]?.contentTypes?.length > 0) {
        cntType_array.push(newArray1[i]?.contentTypes[0]);
      }
      if (newArray1[i]?.topics?.length > 0) {
        for (let k = 0; k < newArray1[i]?.topics.length; k++) {
          topic_array.push(newArray1[i]?.topics[k]);
        }
      }
    }
    const audience_data = [];
    const cntType_data = [];
    const topic_data = [];
    audience_array.map((item, index) => {
      audience_data[index] = item;
    });

    cntType_array.map((item, index) => {
      cntType_data[index] = item;
    });
    topic_array.map((item, index) => {
      topic_data[index] = item;
    });
    var filter = {
      audience: audience_data,
      contentTypes: cntType_data,
      topics: topic_data,
    };
    removeEmptyOrNull(filter);
    const SearchData = {
      searchKeyword: searchKey,
      filter,
    };
    setInitialstate('search');

    getArticleItems(SearchData);
  };
  const removeEmptyOrNull = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const containsObject = (newArray1, selectedVals) => {
    for (let i = 0; i < newArray1.length; i++) {
      for (let j = 0; j < selectedVals.length; ) {
        if (
          newArray1[i].audience[0].value === selectedVals[j].audience[0].value &&
          newArray1[i].contentTypes[0].value ===
            selectedVals[j].contentTypes[0].value &&
          newArray1[i].topics[0].value === selectedVals[j].topics[0].value
        ) {
          return true;
        } else {
          j++;
        }
      }
    }

    return false;
  };
  const handleChangeValue = (index) => {
    let SearchData = '';
    let searchFilterData = '';
    searchKeyword = index;
    const data = [];
    const audience_data = [];
    const cntType_data = [];
    const topic_data = [];
    let audience_array = [];
    let cntType_array = [];
    let topic_array = [];

    for (let i = 0; i < selectedVals?.length; i++) {
      if (selectedVals[i] && selectedVals[i]?.audience?.length > 0) {
        audience_array.push(selectedVals[i]?.audience[0]);
      }
      if (selectedVals[i] && selectedVals[i]?.contentTypes?.length > 0) {
        cntType_array.push(selectedVals[i]?.contentTypes[0]);
      }
      if (selectedVals[i] && selectedVals[i]?.topics?.length > 0) {
        topic_array.push(selectedVals[i]?.topics[0]);
      }
    }

    audience_array.map((item, index) => {
      audience_data[index] = item;
    });
    cntType_array.map((item, index) => {
      cntType_data[index] = item;
    });
    topic_array.map((item, index) => {
      topic_data[index] = item;
    });
    var filter = {
      audience: audience_data,
      contentTypes: cntType_data,
      topics: topic_data,
    };
    removeEmptyOrNull(filter);
    const filterval =
      Object.keys(filter).length === 0 && filter.constructor === Object;
    if (index !== '') {
      setInitialstate('search');
      SearchData = {
        searchKeyword: index,
        filter,
      };
    } else if (filterval === true && index === '') {
      setInitialstate('default');
      SearchData = {
        largeCards: large_Cards,
      };
    } else {
      setInitialstate('search');
      SearchData = {
        filter,
      };
    }

    getArticleItems(SearchData);
    setsearchkey(index);
    setDataItem([]);
    setDataItem('');
  };
  const getArticleItems = (postData) => {
    dataFetcher(BLOG_INSIGHT_CARDBLOCK.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setArticleItems(response?.data?.articleItems);
        response?.data?.articleItems?.length === 0
          ? showSnackbarHandler(openSnackBar, `${t('no-articles-found')}`)
          : setError(false);
      },
      (error) => {
        <Error errorMsg={t('error-message') || error?.message} />;
        setLoading(false);
      }
    );
  };

  const showSnackbarHandler = (openSnackBar, msg, isSuccessMsg = false) => {
    openSnackBar(msg, isSuccessMsg);
  };
  const getArticleFilters = (postData) => {
    dataFetcher(BLOG_INSIGHT_CARDBLOCK.POST_FILTER_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setArticleFilters(response?.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    let imgArticles = [];
    let smallArticles = [];
    if (articleItems && articleItems?.length > 0) {
      imgArticles = articleItems.filter(
        (item) => item?.image?.src && item?.image?.src !== null
      );
      smallArticles = articleItems.filter(
        (item) => item?.image?.src === '' || item?.image?.src === null
      );
    }

    const position = parseInt(jobCardPosition.value) - 1;
    cardAdArticles = imgArticles.concat(smallArticles);

    if (initialstate === 'default' && cardBlocks[0]?.fields?.title?.value) {
      cardAdArticles = insert(cardAdArticles, position, cardBlocks[0]?.fields);
    }
    newArray = cardAdArticles.slice((page - 1) * 10, page * 10);
    setImageCardItems(newArray);
    handlePageScroll();
  }, [articleItems, page]);

  /**
   * @description to set page params
   * @param {number} page - selected page
   * @returns {undefined} - no returns
   */
  const setPageParams = (page) => {
    setCurrentPage(page);
    urlParams.set('page', page);
    history.push({
      pathname: location?.pathname,
      search: String(decodeURIComponent(urlParams)),
    });
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    const pageNo = selectedPage ?? 1;
    if (pageNo !== currentPage || pageNo === 1) {
      setPageParams(pageNo);
    }
  };

  const handlePageScroll = () => {
    return cardRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!hasPageParam) {
      handlePageChange(1);
    }
  }, []);

  const pagination = (
    <section className="search-global-pagination">
      <div className="search-pagination-wrap">
        {totalCount > 10 && (
          <Pagination
            totalRecords={totalCount}
            pageLimit={10}
            onPageChanged={handlePageChange}
            selectedPage={currentPage}
            showPrevNext={true}
            pageNeighbours={1}
            canonical={searchCanonicalUrl?.value?.href}
            searchUrl={searchUrl?.value?.href}
          ></Pagination>
        )}
      </div>

      <SnackBar isActive={isActive} message={message} />
    </section>
  );
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={searchCanonicalUrl?.value?.href + history?.location?.search}
        />
        <meta name="robots" content={`${contentIndex}, ${contentFollow}`} />
      </Helmet>
      <section className="blog card-block insights" ref={cardRef}>
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="col">
                  <ArticleFilters
                    sendDataToParent={handleChangeValue}
                    filterBlocks={articleFilterItem}
                    data={dataItem}
                    setFilterValsToBlocks={handleFilteredValues}
                    cancelFilterValsToBlocks={cancelFilterVals}
                    updateArticle={updateFilterVals}
                    sendFilterItemtoblog={sendFilterItemtoblog}
                    headerClickData={headerData}
                    setSelectedValues={setSelectedValues}
                  />
                </div>
              </div>
              <div className="row">
                {imageCardItems.map((item) =>
                  item?.image?.src ? (
                    <div className="col-lg-6">
                      <CardArticle
                        isFeatured={true}
                        title={item?.headline}
                        subTitle={item?.contentType}
                        date={item?.date}
                        img={item?.image?.src}
                        altText={item?.image?.altText}
                        ctaMode="text"
                        ctaText={t('read-more')}
                        ctaUrl={item?.url}
                        dateFormat={dateFormat ? dateFormat : DATE_FORMATS.LOCALE}
                      />
                    </div>
                  ) : (
                    <div className="col-xl-4 col-md-6">
                      {item?.headline ? (
                        <CardArticle
                          title={item?.headline}
                          subTitle={item?.contentType}
                          date={item?.date}
                          dateFormat={dateFormat ? dateFormat : DATE_FORMATS.LOCALE}
                          ctaMode="text"
                          ctaText={t('read-more')}
                          ctaUrl={item?.url}
                        />
                      ) : (
                        item?.title?.value && (
                          <CardAd
                            title={item?.title}
                            ctaText={item?.profileStatus}
                            ctaUrl={item?.link?.value?.href}
                          />
                        )
                      )}
                    </div>
                  )
                )}
              </div>
              {pagination}
            </>
          )}
        </div>
        <SnackBar isActive={isActive} message={message} />
      </section>
    </>
  );
};

BlogArticleDetailBlock.defaultProps = {
  fields: {
    title: {},
  },
};

BlogArticleDetailBlock.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
  }),
};
export default withTranslation()(
  withPlaceholder([
    { placeholder: 'jss-right', prop: 'filterBlock' },
    { placeholder: 'jss-content', prop: 'cardBlocks' },
  ])(withRouter(withSitecoreContext()(BlogArticleDetailBlock)))
);
