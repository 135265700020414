/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextareaComplex from '../FormFields/Textarea';
import CheckboxGroup from '../FormFields/CheckboxGroup';
import RadioToggle from '../FormFields/RadioToggle';
import { optionsFormat } from '../../../../utils/datafactory';
import './screenerForm.scss';
import '../forms.scss';
import DatePickerField from '../FormFields/DatePickerField';

/**
 * @description Screener Question Form.
 * @param {Object} props - Input props.
 * @returns {Node} HTML Form.
 */
const ScreenerQuestionsForm = (props) => {
  const {
    register,
    errors,
    Validations,
    formData,
    getValues,
    setValue,
    control,
    trigger,
    formName,
    context,
    maxDate,
    minDate,
    dateFormat,
  } = props;
  return (
    <Fragment>
      <form className="screenerForm">
        {formData?.map((data, index) => {
          const formProps = {
            name: `${formName}[${index}][answer]`,
            label: data?.question,
            key: data?.questionID,
            dataValidations: Validations,
            mandatory: data?.mandatory,
            control: control,
            register: register,
            customError: errors,
            getValues: getValues,
            setValue: setValue,
            trigger: trigger,
          };
          return (
            <>
              {/* <input
                type="hidden"
                name={`${formName}[${index}][questionId]`}
                id={`${formName}[${index}][questionId]`}
                value={data?.questionID}
                ref={register ? register : () => {}}
              ></input> */}
              <input
                type="hidden"
                name={`${formName}[${index}][question]`}
                id={`${formName}[${index}][question]`}
                ref={register ? register : () => {}}
                value={data?.question}
              ></input>
              {data?.rejectionReason && (
                <input
                  type="hidden"
                  name={`${formName}[${index}][rejectionReason]`}
                  id={`${formName}[${index}][rejectionReason]`}
                  ref={register ? register : () => {}}
                  value={data?.rejectionReason}
                ></input>
              )}
              <div key={index}>
                <div className="section-heading">{data?.sectionHeading}</div>
                <div className="question">
                  <div className="questionNumber">{`${data?.questionID}. `}</div>
                  <div className="questionText">&nbsp;{data?.question}</div>
                </div>
                {(() => {
                  switch (data?.editType?.toUpperCase()) {
                    case 'FREETEXT':
                    case 'OPEN TEXT':
                      return (
                        <div className="formAnswer">
                          <TextareaComplex
                            {...formProps}
                            id={`freeText_${data?.questionID}`}
                            type={'textarea'}
                            rows={3}
                            isSearchEnabled={false}
                            onEnter={false}
                          />
                        </div>
                      );
                    case 'RADIO BUTTON':
                    case 'MULTIPLE CHOICE':
                      return (
                        <div className="formAnswer radio">
                          <RadioToggle
                            {...formProps}
                            id={`radio_${data?.questionID}`}
                            options={optionsFormat(data?.listofAnswers, 'radio')}
                            type="radio"
                            toggleStyle={{
                              flexDirection:
                                data?.listofAnswers?.length <= 4 ? 'row' : 'column',
                            }}
                          />
                        </div>
                      );
                    case 'SINGLE-SELECT':
                      return (
                        <div className="formAnswer singleSelect">
                          <RadioToggle
                            {...formProps}
                            id={`singleSelect_${data?.questionID}`}
                            options={optionsFormat(
                              data?.listofAnswers,
                              'singleSelect'
                            )}
                            type="radio"
                          />
                        </div>
                      );
                    case 'MULTI-SELECT':
                      return (
                        <Fragment>
                          <div className="formAnswer multiselect">
                            <CheckboxGroup
                              {...formProps}
                              id={`multiSelect_${data?.questionID}`}
                              options={data?.listofAnswers}
                              type="checkbox"
                              requiredValidation={[]}
                            />
                            <input
                              type="hidden"
                              name={`${formName}[${index}][answer]`}
                              ref={register ? register : () => {}}
                            />
                          </div>
                        </Fragment>
                      );
                    case 'DATE':
                      return (
                        <div className="formAnswer  datepicker">
                          <DatePickerField
                            {...formProps}
                            locale={context?.language}
                            format={dateFormat}
                            maxDate={maxDate}
                            minDate={minDate}
                          />
                        </div>
                      );
                    case 'default':
                      return;
                  }
                })()}
              </div>
            </>
          );
        })}
      </form>
    </Fragment>
  );
};

ScreenerQuestionsForm.defaultProps = {
  formData: [],
  Validations: [],
};

ScreenerQuestionsForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  Validations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.func.isRequired,
};

export default ScreenerQuestionsForm;
